<!-- Settings -->
<template>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div class="m-bot-15">
          <div class="row">            
            <div class="col-sm-6 col-xs-12 ">    
              <b-form-group class="mb-3 search-bar">
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    id="filterInput"
                    placeholder="Type to Search"                    
                    auto-complete="nope"
                    v-on:keyup="fetchData(1)"
                  ></b-form-input>
                  <span class="search-icon" v-if="filter" id="clear-button">
                  <i class="fas fa-times" @click="filter =''"></i>
                  <b-tooltip target="clear-button" variant="primary">Clear</b-tooltip>
                </span>
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="(filter)?fetchData(1):''" variant="primary">Search</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>          
            <!-- <div class="col-sm-6 col-xs-12 text-right">
              <b-button class="rounded-0 btn btn-sm btn-primary font-weight-bold text-uppercase" variant="primary" @click="createSupplier"><i class="fa fa-plus"></i>Add New Supplier
              </b-button>             
           </div> -->
          <div class="col-sm-6 col-xs-12 text-right">
              <b-button class="rounded-3 btn btn-sm btn-primary font-weight-bold text-uppercase" variant="primary" @click="createSupplier"><i class="fa fa-plus"></i>Add Supplier
              </b-button>
              <b-button class="rounded-3 btn btn-sm btn-warning font-weight-bold text-uppercase ml-3" variant="primary" @click="ImportSupplier"><i class="fa fa-upload" style="font-size:12px;margin-top:-5px;"></i> Import 
              </b-button>
              <!-- <b-button class="btn-sm btn-filter rounded-3" v-b-toggle.collapse-1 variant="primary" id="tooltip-button-variant"><i class="fa fa-filter" aria-hidden="true"></i></b-button>
               <b-tooltip target="tooltip-button-variant" variant="primary">Column Filter</b-tooltip> -->
            </div>         
        </div>
        <div class="row mt-5">
          <div class="col-sm-12">
            <el-table :data="list" border element-loading-text="Loading" style="width: 100%" v-loading="loading"> 
               <el-table-column label="Supplier ID" sortable width="150" >
                <template slot-scope="scope">
                  <el-tag slot="reference" size="mini"><a href="#" @click.prevent="viewSupplier(scope.row.id)">{{scope.row.supplier_Id}}</a></el-tag>                                
                </template>
              </el-table-column>             
              <el-table-column label="Company Name"  width="150" >
                <template slot-scope="scope">
                  <el-tag slot="reference" size="mini"><a href="#" @click.prevent="viewSupplier(scope.row.id)">{{scope.row.company_name}}</a></el-tag>                                                
                </template>
              </el-table-column>
              <el-table-column label="Supplier Name" prop="supplier_name"  width="150" >
                <template slot-scope="scope">
                <span>{{(scope.row.first_name) ? scope.row.first_name : ""}} {{(scope.row.last_name) ? scope.row.last_name : ""}}</span>
                </template>
              </el-table-column>
              <el-table-column label="Mobile" prop="mobile"  width="150">
                <template slot-scope="scope">
                {{(scope.row.mobile) ?'+1 ' +scope.row.mobile : ""}}
                </template>
              </el-table-column>
              <el-table-column label="Email" prop="customer_mobile"  width="200">
                <template slot-scope="scope">
                {{scope.row.email_address}} 
                </template>
              </el-table-column> 
               <el-table-column label="Website" prop="website"  width="150" >
                <template slot-scope="scope">
                {{scope.row.website}} 
                </template>
              </el-table-column> 
               <el-table-column label="Category" prop="category" sortable width="150" >
                <template slot-scope="scope">
               {{scope.row.category? scope.row.category.label:''}} 
                </template>
              </el-table-column>                
                <el-table-column label="Term" prop="term"  width="100" >
                <template slot-scope="scope">
                {{scope.row.term? scope.row.term.term_name:''}} 
                </template>
              </el-table-column> 
               <el-table-column label="Regular %" prop="regular"  width="100" >
                <template slot-scope="scope">
                {{scope.row.regular_percentage? scope.row.regular_percentage + ' %':''}} 
                </template>
              </el-table-column> 
               <el-table-column label="Contractor %" prop="contractor"  width="120" >
                <template slot-scope="scope">
                {{scope.row.contractor_percentage? scope.row.contractor_percentage +' %':''}} 
                </template>
              </el-table-column>
               <el-table-column label="Dealer %" prop="dealer"  width="100" >
                <template slot-scope="scope">
                {{scope.row.dealer_percentage? scope.row.dealer_percentage +' %':''}} 
                </template>
              </el-table-column>
               <el-table-column label="Inside %" prop="inside"  width="100" >
                <template slot-scope="scope">
                {{scope.row.inside_percentage? scope.row.inside_percentage +' %':''}} 
                </template>
              </el-table-column>
               <el-table-column label="Status" width="100" align="center">
                <template slot-scope="scope">
               <el-switch :active-value="1" :inactive-value="0"  v-model="scope.row.active"  @change="updateStatus(scope.row.id, scope.row.active)" style="margin:0px"></el-switch>                                       
                </template>
              </el-table-column>             
                <el-table-column fixed="right" align="center" label="Actions" width="80">
                <template slot-scope="scope">
                  <el-dropdown @command="handleActions($event,scope.row)" size="medium">
                    <i class="flaticon2-menu-1"></i>
                    <el-dropdown-menu slot="dropdown" class="action-dropdown">
                      <el-dropdown-item :command="1" ><i class="fa fa-eye"></i>View</el-dropdown-item>                      
                      <el-dropdown-item :command="2" ><i class="fa fa-pen"></i>Edit</el-dropdown-item>
                      <el-dropdown-item :command="3" ><i class="fa fa-trash"></i>Delete</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <el-pagination v-if="!loading" style="margin-top:15px;"
                background
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange"
                @current-change="fetchData" 
                :page-count="pagination.last_page" 
                :page-sizes="[10, 25, 50, 100]"
                :page-size="pagination.per_page"
                :current-page.sync="pagination.current_page" 
                :total="pagination.total_rows"
                >
            </el-pagination>
          </div>
        </div>
        </div>
        <v-dialog/>
    </div>
  </div>
</template>


<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {getAllSuppliers, deleteSupplier, changeStatus} from "@/api/supplier";
//import ImportSupplier from '@/components/CreateSupplier/ImportSupplier';
import { globalEvents } from '@/utils/globalEvents'
import _ from 'lodash';
  export default {
    name: "list-supplier",
    data() {
      return {
        list: [],
         pagination:{
          total_rows: 1,
          current_page: 1,
          last_page:null,
          per_page: 50,
        },     
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],        
      }
    },
    watch:{
      filter : _.debounce(function(n){
      this.loading = true;
        if (!n) {
          this.fetchData(1);
        }
      }, 200)
    },
    computed: {
    
    },
    components: {
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Dashboard", route: "/dashboard" },
        { title: "Suppliers", route: "/supplier/list" },
      ]);

        globalEvents.$on('importSupplier', type => {
              this.fetchData(1); 
               if (type) {
                 console.log(type);           
               }});
    },
    created(){
      this.fetchData(1);
    },
    methods:{
      updateStatus(supplier_id,status){
        changeStatus(supplier_id,status).then(response => {
          this.$showResponse('success', response.data.message);
        })},
      fetchData(p) {
        this.loading = true
        let params = { page: p }
        if(this.filter){
          params.filter = this.filter
        }
        if(this.sort){
          params.sort = this.sort;
        }
        if(this.pagination.per_page){
          params.per_page = this.pagination.per_page;
        }
        getAllSuppliers(params).then(response => {
          this.list = response.data.data.data
          this.pagination.current_page = response.data.data.current_page
          this.pagination.last_page = response.data.data.last_page
          this.pagination.total_rows = response.data.data.total
          this.loading = false
          this.$set(this.list, '_showDetails', true)
        })
      },
      createSupplier(){
        this.$router.push({path:'/supplier/create'})
      },
       ImportSupplier(){
        this.$router.push({path:'/supplier/import'})
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      handleSizeChange(per_page){
        this.pagination.per_page = per_page;
        this.fetchData(1);
      },  
      viewSupplier(supplier_id){
         this.$router.push({path:'/supplier/view/'+supplier_id})
      },    
      handleActions(evt, row)
      {
          if (evt === 1){
            this.$router.push({path:'/supplier/view/'+row.id})
          }
          else if (evt === 2){
            this.$router.push({path:'/supplier/edit/'+row.id})
          }
          else if (evt === 3){
            this.$bvModal.msgBoxConfirm('Are you sure you want to delete ?', {
              title: 'Please Confirm',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
            }).then(value => {
              if(value){
                this.loading = true;
                deleteSupplier(row.id).then(response => {
                  if(response.data.status){
                      this.fetchData(1)
                      this.$showResponse('success', response.data.message);
                  }
                  this.loading = false;
                })
              }else{
                return false;
              }
            })
          }
        },
    }
  }
</script>